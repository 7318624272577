<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loadingState">
                <b-row>
                  <b-col lg="12" sm="12">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                        <b-col lg="12" sm="12">
                          <div class="mt-2">
                            <b-table-simple striped bordered small>
                                <b-tr>
                                  <b-th>{{ $t('financialAid.fiscal_year') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.fiscal_year_bn : demmandDetailsData.fiscal_year }}</b-td>
                                  <b-th>{{ $t('financialAid.season') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.season_bn : demmandDetailsData.seasson }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('financialAid.circular') }}</b-th>
                                  <b-td colspan="3">{{ ($i18n.locale=='bn')? demmandDetailsData.circular_bn : demmandDetailsData.circular }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('financialAid.project') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.project_bn : demmandDetailsData.project }}</b-td>
                                  <b-th>{{ $t('globalTrans.division') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.division_name_bn : demmandDetailsData.division_name }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('globalTrans.district') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.district_name_bn : demmandDetailsData.district_name }}</b-td>
                                  <b-th>{{ $t('globalTrans.upazila') }}</b-th>
                                  <b-td colspan="3">{{ ($i18n.locale=='bn') ? demmandDetailsData.upazilla_name_bn : demmandDetailsData.upazilla_name }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            <b-row>
                              <b-col lg="12" sm="12">
                                  <template>
                                    <div class="col-sm-12 col-md-12" style="font-size:14px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center">
                                            {{ $t('financialAid.demand_summary') }}
                                        </h5>
                                    </div>
                                  </template>
                                  <div v-for="(detail, indx) in demand_details" :key="indx" class="mt-1">
                                    <h5>{{ $t('globalTrans.union') }}: {{ $i18n.locale === 'bn' ? detail.union_name_bn : detail.union_name }} </h5>
                                    <b-table-simple striped bordered small class="mt-2">
                                      <b-thead>
                                      <b-tr>
                                        <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('common_config.farmer_father_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('common_config.nid_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('common_config.mobile_no') }}</b-th>
                                        <b-th class="text-center">
                                          <strong>
                                            <b-form-checkbox
                                                id="checkbox-all"
                                                v-model="checkUncheckAll"
                                                name="checkbox-all"
                                            >
                                              {{ $t('globalTrans.checkAll') }}
                                            </b-form-checkbox>
                                          </strong>
                                        </b-th>
                                      </b-tr>

                                      </b-thead>
                                      <b-tbody>
                                        <b-tr v-for="(detail,index) in detail.farmer_info" :key="index" class="text-center">
                                            <b-td>{{ $n(index+1) }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}</b-td>
                                            <b-td>{{ $n(detail.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detail.mobile_no, { useGrouping: false } ) }}</b-td>
                                          <b-td style="text-align: center; v-align: middle;">
                                              <span>
                                                <b-form-checkbox
                                                    :id="'detail-' + index"
                                                    v-model="detail.is_check"
                                                    :name="'detail-' + index"
                                                >
                                              </b-form-checkbox>
                                              </span>
                                          </b-td>
                                        </b-tr>
                                      </b-tbody>
                                    </b-table-simple>
                                  </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="7" lg="7" class="mb-5">
                              </b-col>
                              <b-col md="5" lg="5" class="text-right">
                                <b-button type="button" @click="forwardPageShow = !forwardPageShow" variant="primary" class="mr-2">{{ $t('budget.forward')}}</b-button>
                                <b-button type="submit" variant="warning" class="mr-2" @click="reject">{{ $t('financialAid.reject') }}</b-button>
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                              </b-col>
                            </b-row>
                            <div v-if="forwardPageShow" class="mt-3">
                            <b-row>
                              <b-col md="6" lg="6">
                                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="3"
                                      label-for="office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $t('globalTrans.office') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="form.office_id"
                                        :options="officeList"
                                        id="office_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col md="6" lg="6">
                                <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="3"
                                      label-for="designation_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{ $t('budget.designation') }} <span class="text-danger">*</span>
                                      </template>
                                  <b-form-select
                                      plain
                                      v-model="form.designation_id"
                                      :options="designationList"
                                      id="designation_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col md="6" lg="6">
                                <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="3"
                                      label-for="receiver_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{ $t('budget.officer') }} <span class="text-danger">*</span>
                                      </template>
                                  <b-form-select
                                      plain
                                      v-model="form.receiver_id"
                                      :options="officerList"
                                      id="receiver_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col md="6" lg="6">
                                <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="3"
                                      label-for="comment"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-textarea
                                          plain
                                          v-model="form.comment"
                                          id="comment"
                                          placeholder="Maximum 200 word.."
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-textarea>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="9" lg="9">
                              </b-col>
                              <b-col md="3" lg="3" class="text-right">
                                <div class="mb-3">
                                  <b-button type="submit" @click="forward" variant="primary" class="mr-2">{{ $t('globalTrans.save')}}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                              </b-col>
                            </b-row>
                              </div>
                          </div>
                        </b-col>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser, farmerDataApi, upazillaDemandRcvList, demandApproveForward } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.demmandDetailsData = this.getDetailsData()
    this.farAddressData = this.getFarmerInformation(this.demmandDetailsData.nid_no)
    this.getForwardDemandList(this.item.circular_id, this.item.upazilla_id)
    this.officeIdList = []
    this.getOfficeIdList(this.authUser.office_id)
    this.getAssignDesignationList()
  },
  mounted () {
    this.officeList = this.officeIdList.map(officeId => {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      return { value: office.value, text: this.$i18n.locale === 'en' ? office.text_en : office.text_bn }
    })
  },
  data () {
    return {
      demand_details: [],
      demmandDetailsData: {},
      farAddressData: {},
      loadingState: false,
      officerLoading: false,
      officerList: [],
      forwardPageShow: false,
      form: {
        fiscal_year_id: 0,
        circular_id: 0,
        office_id: 0,
        status: 3,
        demand_id: this.id,
        designation_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        comment: '',
        allContent: [],
        is_normal_forward: true
      },
      officeIdList: [],
      officeList: [],
      assignDesignationList: [],
      designationList: [],
      demand_details_all: [],
      checkUncheckAll: true
    }
  },
  watch: {
    'form.office_id': function (newValue) {
      this.getDesignationList(newValue)
    },
    'form.designation_id': function (newValue) {
      this.getOfficerList(newValue)
    },
    checkUncheckAll: function (newValue) {
      if (newValue) {
        this.demand_details.map(item => {
          item.farmer_info.map(farmerItem => {
            farmerItem.is_check = true
          })
        })
      } else {
        this.demand_details.map(item => {
          item.farmer_info.map(farmerItem => {
            farmerItem.is_check = false
          })
        })
      }
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  methods: {
    getDetailsData () {
      return JSON.parse(JSON.stringify(this.item))
    },
    async getOfficerList (designationId) {
        this.officerLoading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${this.form.office_id}/${designationId}`)
        .then(response => {
            if (response.success) {
              this.officerList = []
              const data = response.data
              this.officerList = data.map(item => {
                return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
              })
            } else {
                this.officerList = []
            }
            this.officerLoading = false
        })
    },
    async getFarmerInformation (nid) {
      this.loadingState = true
      const params = Object.assign({}, { nid: nid })
      await RestApi.getData(incentiveGrantServiceBaseUrl, farmerDataApi, params).then(response => {
        if (response.success) {
          this.demmandDetailsData.farmer_data.gender = response.data.gender_id === 1 ? 'male11' : 'Female'
          const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
          this.demmandDetailsData.division_id = response.data.division_id
          this.farAddressData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
          const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
          this.demmandDetailsData.farmer_data.district_id = response.data.district_id
          this.demmandDetailsData.farmer_data.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
          const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
          this.demmandDetailsData.farmer_data.upazilla = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
          const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
          this.demmandDetailsData.farmer_data.union = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
          const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
          this.demmandDetailsData.farmer_data.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
          const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
          this.demmandDetailsData.farmer_data.per_district_id = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
          const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
          this.demmandDetailsData.farmer_data.per_upazilla = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
          const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
          this.demmandDetailsData.farmer_data.per_union = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
          const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
          this.demmandDetailsData.farmer_data.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''
        }
      })
      // this.loadingState = false
      return this.demmandDetailsData
    },
    async getForwardDemandList (circularId, upazillaId) {
      this.loadingState = true
      let params = Object.assign({}, { circular_id: this.item.circular_id, fiscal_year_id: this.item.fiscal_year_id, upazilla_id: upazillaId })
      if (this.$store.state.Auth.authUser.role_id !== 1) {
        const officeId = this.$store.state.Auth.authUser.office_id
        params = Object.assign({}, params, {
          office_id: officeId
        })
      }
      await RestApi.getData(incentiveGrantServiceBaseUrl, upazillaDemandRcvList, params).then(response => {
        if (response.success) {
          this.demand_details = this.getDemandRelatinalData(response.data)
          this.loadingState = false
        } else {
          this.loadingState = false
        }
      })
    },
    getDemandRelatinalData (data) {
      this.demand_details_all = []
      const listData = data.map((item, index) => {
        const union = this.$store.state.commonObj.unionList.find(un => un.value === item.union_id)
        const unionObj = {}
        unionObj.union_name = union !== undefined ? union.text_en : ''
        unionObj.union_name_bn = union !== undefined ? union.text_bn : ''
        item.farmer_info = item.farmer_info.map(farmer => {
          const deReqObj = {
            far_general_info_id: farmer.far_general_info_id,
            farmer_name: farmer.farmer_data.name,
            farmer_name_bn: farmer.farmer_data.name_bn,
            father_name: farmer.farmer_data.father_name,
            father_name_bn: farmer.farmer_data.father_name_bn,
            nid_no: farmer.farmer_data.nid_no,
            mobile_no: farmer.farmer_data.mobile_no,
            is_check: true
          }
          const farmerObjCustom = Object.assign({}, farmer, deReqObj)
          this.demand_details_all.push(farmerObjCustom)
          return Object.assign({}, farmer, deReqObj)
        })
        return Object.assign({}, item, unionObj)
      })
      return listData
    },
    async saveData () {
      this.loadingState = true
      Object.assign(this.form, { allContent: this.demand_details })
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, demandApproveForward, this.form)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loadingState = false
      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    },
    getOfficeIdList (officeId) {
      this.officeIdList.push(officeId)
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      if (office.parent_office_id) {
        this.getOfficeIdList(office.parent_office_id)
      }
      return this.officeIdList
    },
    async getAssignDesignationList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(commonServiceBaseUrl, 'assign-designation/list-all')
      if (result.success) {
        this.assignDesignationList = result.data
      } else {
        this.assignDesignationList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getDesignationList (officeId) {
      const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId)
      this.designationList = assignDesignationList.map(item => {
        const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
        return designation
      })
    },
    reject () {
      this.form.status = 5
    },
    forward () {
      this.form.status = 3
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
